<template>
	<v-dialog v-model="editMode" persistent max-width="600px">
		<template v-slot:activator="{ on }">
			<v-btn icon color="warning" v-on="on">
				<v-icon>mdi-pencil</v-icon>
			</v-btn>
		</template>
		<v-card rounded="xl">
			<v-card-title>
				<span class="headline">{{ $t('offers.benefitsTitle') }}</span>
				<v-spacer />
				<v-btn icon @click="switchEditMode()">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text>
				<v-form ref="form" v-model="valid">
					<v-select
						v-model="thesisOptions"
						:label="$t('offers.thesisOptions')"
						:items="availableThesisOptions"
						item-text="name"
						item-value="key"
						:rules="rules.thesisOptions"
						chips
						deletable-chips
						multiple
						return-object
						outlined
						rounded
						hide-details="auto"
						class="pb-3"
					/>
					<v-text-field
						v-model="salary"
						:label="$t('offers.salary')"
						:hint="$t('offers.salaryHelp')"
						persistent-hint
						:rules="rules.salary"
						outlined
						rounded
						hide-details="auto"
						class="pb-3"
					/>
					<v-textarea
						v-model="benefits"
						:label="$t('offers.benefits')"
						:counter="benefitsMaxLength"
						:rules="rules.benefits"
						outlined
						rounded
						hide-details="auto"
						class="pb-3"
						auto-grow
					/>
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-spacer />
				<v-btn color="blue darken-1" text rounded plain @click="callEdit()">
					{{ $t('settings.edit') }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'OfferBenefitsUpdateForm',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loading: true,
			editMode: false,
			valid: false,
			benefits: '',
			salary: '',
			thesisOptions: [],
			rules: {
				benefits: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.benefitsMaxLength) || this.$t('rules.length', { length: this.benefitsMaxLength })
				],
				salary: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.salaryMaxLength) || this.$t('rules.length', { length: this.salaryMaxLength })
				],
				thesisOptions: [
					(v) => !!v.length || this.$t('rules.required'),
					(v) => (v && this.allItemsInArray(v, this.availableThesisOptions)) || this.$t('rules.notSupportedAtleast')
				]
			}
		}
	},
	computed: {
		benefitsMaxLength() {
			return 1024 * 2
		},
		salaryMaxLength() {
			return 64
		},
		...mapGetters({
			offer: 'offers/offer',
			availableThesisOptions: 'data/availableThesisOptions'
		})
	},
	created() {
		this.fetchAvailableThesisOptions().then(() => {
			this.loading = false
		})
		this.thesisOptions = this.offer.info.thesisOptions
		this.salary = this.offer.info.salary
		this.benefits = this.offer.info.benefits
	},
	methods: {
		allItemsInArray(items, array) {
			for (let index = 0; index < items.length; index++) {
				if (!array.map((option) => option.key).includes(items[index].key)) return false
			}
			return true
		},
		switchEditMode() {
			this.editMode = !this.editMode
		},
		callEdit() {
			this.$refs.form.validate()
			if (!this.valid) return
			this.loading = true
			this.editOffer({
				id: this.offer.id,
				externalLink: this.offer.externalLink,
				title: this.offer.title,
				city: this.offer.info.location.city,
				province: this.offer.info.location.province,
				country: this.offer.info.location.country,
				description: this.offer.info.description,
				startingDate: this.offer.info.startingDate,
				duration: this.offer.info.duration,
				offerType: this.offer.info.type,
				fulltime: this.offer.info.time.key,
				thesisOptions: this.thesisOptions,
				salary: this.salary,
				benefits: this.benefits,
				sectors: this.offer.info.sectors,
				qualifications: this.offer.info.qualifications
			}).then(() => {
				this.switchEditMode()
				this.loading = false
			})
		},
		...mapActions('offers', ['editOffer']),
		...mapActions('data', ['fetchAvailableThesisOptions'])
	}
}
</script>
